// @flow

import { administrableContentCategories, automaticContentCategories } from 'constants/fakeContent';

export type SocialNetworkConfigType = {
  facebook: string,
  twitter: string,
  instagram: string,
  youtube: string,
  linkedin: string,
};

export type PreviousSiteConfigType = {
  news: string,
  events: string,
  history: string,
  prizeList: string,
};

export type SiteCustomizationType = {
  url: string,
  name: string,
  banner: string,
  bannerName: string,
  theme: {
    primary: string,
    secondary: string,
  },
};

export type ConfigType = {
  administrableContent: string[],
  automaticContent: string[],
  socialNetworks: SocialNetworkConfigType,
  step: void | number,
  previousSite: PreviousSiteConfigType,
  siteCustomization: SiteCustomizationType,
  admin: string[],
};

export type ClubType = {
  embleme: string,
  nom: string,
  code: string,
  site_status: string,
  email: string,
  emailOfficiel: string,
  configuration: ConfigType,
  url_front: string,
  url_back: string,
  emailOfficiel: string,
  string_blacklist: string[],
  domaines_blacklist: string[],
};

const nullSocialNetworkState: SocialNetworkConfigType = {
  facebook: '',
  twitter: '',
  instagram: '',
  youtube: '',
  linkedin: '',
};

const nullPreviousSiteConfig: PreviousSiteConfigType = {
  news: '',
  events: '',
  history: '',
  prizeList: '',
};

const nullsiteCustomization: SiteCustomizationType = {
  url: '',
  name: '',
  banner: '',
  bannerName: '',
  theme: {
    primary: '',
    secondary: '',
  },
};

const initialAutoContentSelection = automaticContentCategories.reduce((acc, content) => {
  acc.push(content.slug);
  return acc;
}, []);

const initialAdministrationContentSelection = administrableContentCategories.reduce((acc, content) => {
  acc.push(content.slug);
  return acc;
}, []);

export const nullClubConfig: ConfigType = {
  automaticContent: [...initialAutoContentSelection],
  administrableContent: [...initialAdministrationContentSelection],
  socialNetworks: nullSocialNetworkState,
  previousSite: nullPreviousSiteConfig,
  siteCustomization: nullsiteCustomization,
  admin: [],
  step: undefined,
};

export const nullClub: ClubType = {
  embleme: '',
  nom: '',
  code: '',
  email: '',
  emailOfficiel: '',
  configuration: nullClubConfig,
  site_status: 'hors_ligne',
  url_front: '',
  url_back: '',
  string_blacklist: [],
  domaines_blacklist: [],
};
