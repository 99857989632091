// @flow

import type { ClubType, ConfigType } from 'types/Club';

export const FETCH_CLUB_START = 'FETCH_CLUB_START';
export const FETCH_CLUB_SUCCESS = 'FETCH_CLUB_SUCCESS';
export const FETCH_CLUB_FAILURE = 'FETCH_CLUB_FAILURE';
export const POST_CONFIG_START = 'POST_CONFIG_START';
export const POST_CONFIG_SUCCESS = 'POST_CONFIG_SUCCESS';
export const POST_CONFIG_FAILURE = 'POST_CONFIG_FAILURE';
export const POST_ACTION_START = 'POST_ACTION_START';
export const POST_ACTION_SUCCESS = 'POST_ACTION_SUCCESS';
export const POST_ACTION_FAILURE = 'POST_ACTION_FAILURE';
export const GET_HIT_FOR_GTM = 'GET_HIT_FOR_GTM';
export const GET_HIT_FOR_GTM_SUCCESS = 'GET_HIT_FOR_GTM_SUCCESS';

export type FETCH_CLUB_START_ACTION = {
  type: 'FETCH_CLUB_START',
  payload: {
    id: number
  }
};

export type FETCH_CLUB_FAILURE_ACTION = {
  type: 'FETCH_CLUB_FAILURE'
};

export type FETCH_CLUB_SUCCESS_ACTION = {
  type: 'FETCH_CLUB_SUCCESS',
  payload: {
    club: ClubType,
  }
};
export type POST_CONFIG_START_ACTION = {
  type: 'POST_CONFIG_START',
  payload: {
    id: number,
    config: ConfigType,
  }
};

export type POST_CONFIG_FAILURE_ACTION = {
  type: 'POST_CONFIG_FAILURE'
};

export type POST_CONFIG_SUCCESS_ACTION = {
  type: 'POST_CONFIG_SUCCESS',
  payload: {
    club: ClubType
  }
};

export type POST_ACTION_START_ACTION = {
  type: 'POST_ACTION_START',
  payload: {
    id: number,
    config: ConfigType,
    action: string,
    email: string,
  }
};

export type POST_ACTION_FAILURE_ACTION = {
  type: 'POST_ACTION_FAILURE'
};

export type POST_ACTION_SUCCESS_ACTION = {
  type: 'POST_ACTION_SUCCESS',
  payload: {
    club: ClubType
  }
};

export type GET_HIT_FOR_GTM_ACTION = {
  type: 'GET_HIT_FOR_GTM',
  payload: {
    id: number
  }
}

export type GET_HIT_FOR_GTM_SUCCESS_ACTION = {
  type: 'GET_HIT_FOR_GTM_SUCCESS',
  payload: {
    club: ClubType,
  }
}

export type Action =
| FETCH_CLUB_START_ACTION
| FETCH_CLUB_FAILURE_ACTION
| FETCH_CLUB_SUCCESS_ACTION
| POST_CONFIG_START_ACTION
| POST_CONFIG_FAILURE_ACTION
| POST_CONFIG_SUCCESS_ACTION
| POST_ACTION_START_ACTION
| POST_ACTION_FAILURE_ACTION
| POST_ACTION_SUCCESS_ACTION
| GET_HIT_FOR_GTM_ACTION
| GET_HIT_FOR_GTM_SUCCESS_ACTION;


export function fetchClub(id: number): FETCH_CLUB_START_ACTION {
  return {
    type: FETCH_CLUB_START,
    payload: { id }
  };
}

export function clubFetched(club: ClubType): FETCH_CLUB_SUCCESS_ACTION {
  return {
    type: FETCH_CLUB_SUCCESS,
    payload: { 
      club,
    }
  };
}

export function clubFetchError(error: any): FETCH_CLUB_FAILURE_ACTION {
  return {
    type: FETCH_CLUB_FAILURE
  };
}

export function postClubConfig(id: number, config: ConfigType): POST_CONFIG_START_ACTION {
  return {
    type: POST_CONFIG_START,
    payload: {
      id,
      config,
    }
  };
}

export function postClubConfigSuccess(club: ClubType): POST_CONFIG_SUCCESS_ACTION {
  return {
    type: POST_CONFIG_SUCCESS,
    payload: { club }
  };
}

export function postClubConfigError(error: any): POST_CONFIG_FAILURE_ACTION {
  return {
    type: POST_CONFIG_FAILURE
  };
}

export function postClubAction(
  id: number,
  config: ConfigType,
  action: string,
  email: string
): POST_ACTION_START_ACTION {
  return {
    type: POST_ACTION_START,
    payload: {
      id,
      config,
      action,
      email,
    }
  };
}

export function postClubActionSuccess(club: ClubType): POST_ACTION_SUCCESS_ACTION {
  return {
    type: POST_ACTION_SUCCESS,
    payload: { club }
  };
}

export function postClubActionError(error: string): POST_ACTION_FAILURE_ACTION {
  return {
    type: POST_ACTION_FAILURE
  };
}

export function getHitForGtm (id: number): GET_HIT_FOR_GTM_ACTION {
  return {
    type: GET_HIT_FOR_GTM,
    payload: { id }
  };
}

export function getHitForGtmSuccess(club: ClubType): GET_HIT_FOR_GTM_SUCCESS_ACTION {
  return {
    type: GET_HIT_FOR_GTM_SUCCESS,
    payload: { club }
  };
}
