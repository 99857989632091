// @flow
import App from 'components/app/App';
import { connect } from 'react-redux';

import { fetchSettings } from 'actions/appActions';
import { fetchMenu } from 'actions/menuActions';
import { displayModal } from 'actions/modalActions';
import { stockMagicLink, stockClubID } from 'actions/magicLinkActions';
import { fetchClub } from 'actions/clubActions';

import type { StateProps, DispatchProps } from 'components/app/App';
import type { StateType, DispatchType } from 'types/Actions';
import { withRouter } from 'react-router-dom';

export default withRouter(
  connect(
    (state: StateType): StateProps => ({
      favicons: state.settingsState.favicons,
      titleLigue: state.settingsState.title,
      magicLink: state.magicLinkState.magicLink,
      club: state.clubState.club,
      clubID: state.magicLinkState.clubID,
    }),
    (dispatch: DispatchType): DispatchProps => ({
      fetchSettings: () => dispatch(fetchSettings()),
      fetchMenu: () => dispatch(fetchMenu()),
      stockMagicLink: (magicLink: string) => dispatch(stockMagicLink(magicLink)),
      fetchClub: (id: number) => dispatch(fetchClub(id)),
      displayModal: (modalNb: number) => dispatch(displayModal(modalNb)),
      stockClubID: (clubID: number) => dispatch(stockClubID(clubID)),
    })
  )(App)
);
