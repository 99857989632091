// @flow

import { fork, spawn, all } from 'redux-saga/effects';
import { ON_BOARDING_BASE_URL } from 'constants/api';
import api from 'infrastructure/http/index';
import menuSaga from 'sagas/menuSaga';
import settingsSaga from 'sagas/settingsSaga';
import clubSaga from 'sagas/clubSaga';
import magicLinkSaga from 'sagas/magicLinkSaga';
import contactFormSaga from 'sagas/contactFormSaga';
import newsletterSubscriptionSaga from 'sagas/newsletterSubscriptionSaga';
import hitTagsSaga from 'sagas/hitTagsSaga';

export type GeneratorType = Generator<*, *, *>;

export default function*(): GeneratorType {
  yield fork(bootstrap);
}

function* bootstrap(): GeneratorType {
  // const apiExecutor = api(BASE_URL); @TODO
  const onBoardingApiExecutor = api(ON_BOARDING_BASE_URL);

  try {
    yield all([
      spawn(hitTagsSaga()),
      spawn(settingsSaga(onBoardingApiExecutor)),
      spawn(menuSaga(onBoardingApiExecutor)),
      spawn(clubSaga(onBoardingApiExecutor)),
      spawn(magicLinkSaga(onBoardingApiExecutor)),
      spawn(contactFormSaga(onBoardingApiExecutor)),
      spawn(newsletterSubscriptionSaga(onBoardingApiExecutor)),
    ]);
  } catch (e) {
    // @TODO
  }
}
