export const automaticContentCategories = [
  {
    id: 1,
    nom: 'Matchs en direct',
    slug: 'matchs-direct',
    icon: 'icon-store',
    description: 'Suivi du match en temps réel : score, actions de jeu, remplacements, cartons et statistiques.',
    img: '/img/help/match_direct.png'
  },
  {
    id: 2,
    nom: 'Résumés et effectifs de matchs',
    slug: 'resumes-matchs',
    description: 'Temps forts et composition des équipes des matchs passés.',
    icon: 'icon-account',
    img: '/img/help/resume_effectif.png'
  },
  {
    id: 3,
    nom: 'Calendrier et résultats des matchs',
    slug: 'calendrier-matchs',
    description: 'L\'actualité de vos équipes à travers leurs résultats et les matchs à venir.',
    icon: 'icon-calendar',
    img: '/img/help/calendrier.jpg'
  },
  {
    id: 4,
    nom: 'Offre de pratique',
    slug: 'pratiques-offre',
    description: 'Toutes les pratiques du rugby proposées dans votre club.',
    icon: 'icon-external',
    img: '/img/help/offre_pratiques.png'
  },
  {
    id: 5,
    nom: 'Statistiques du club',
    slug: 'statistiques-club',
    description: 'Les performances de la saison en cours.',
    icon: 'icon-like-fb',
    img: '/img/help/statistiques_club.png'
  },
  {
    id: 6,
    nom: "Lieux d'entrainement",
    slug: 'lieux-entrainement',
    description: 'Toutes les indications nécessaires sur les entraînements.',
    icon: 'icon-place',
    img: '/img/help/entrainements.jpg'
  },
  {
    id: 7,
    nom: 'Info. & statistiques des équipes',
    slug: 'statistiques-equipes',
    description: 'Toutes les informations sur les équipes du club.',
    icon: 'icon-search',
    img: '/img/help/statistiques_equipes.png'
  },
  {
    id: 8,
    nom: 'Respons. & contacts administratifs',
    slug: 'contacts-responsables',
    description: 'Les contacts du club.',
    icon: 'icon-coment-fb',
    img: '/img/help/contacts_clubs.jpg'
  },
];

export const administrableContentCategories = [
  {
    id: 101,
    nom: "Articles, résumé de match...",
    slug: 'articles',
    description: 'Vos contenus rédigés sur les matchs du club',
    image: 'content-score',
    img: '/img/help/articles_resumes.jpg'
  },
  {
    id: 102,
    nom: "Structure et organigramme",
    slug: 'structure-organigramme',
    description: 'Tout comprendre de l\'organisation du *club*',
    image: 'content-structure',
    img: '/img/help/structure_organigramme.png'
  },
  {
    id: 103,
    nom: "Histoire du club",
    slug: 'histoire',
    description: 'Votre club, de sa naissance à aujourd\'hui',
    image: 'content-history',
    img: '/img/help/histoire.jpg'
  },
  {
    id: 104,
    nom: "Newsletter",
    slug: 'newsletter',
    description: 'Connecter votre outil de newsletter',
    image: 'content-newsletter',
    img: '/img/help/newsletter.jpg'
  },
  {
    id: 105,
    nom: "Palmarès",
    slug: 'palmares',
    description: 'Vos plus belles victoires recensées et mises en avant',
    image: 'content-palmares',
    img: '/img/help/palmares.jpg'
  },
  {
    id: 106,
    nom: "Évènements",
    slug: 'evenements',
    description: 'L\'ensemble des événements organisés par le club',
    image: 'content-events',
    img: '/img/help/evenements.jpg'
  },
  {
    id: 107,
    nom: "Photos et vidéos",
    slug: 'mediatheque',
    description: 'Toutes les photos et vidéos mises à disposition sur cet espace',
    image: 'content-media',
    img: '/img/help/photos_videos_mur.jpg'
  },
  {
    id: 108,
    nom: "Partenaires",
    slug: 'partenaires',
    description: 'Un espace dédié à vos partenaires : présentation, actualités, recrutement de futurs partenaires',
    image: 'content-partners',
    img: '/img/help/partenaires.jpg'
  },
  {
    id: 109,
    nom: "Bénévoles",
    slug: 'benevoles',
    description: 'Un espace consacré au bénévolat dans votre structure',
    image: 'content-volunteers',
    img: '/img/help/benevoles.png'
  },
  {
    id: 110,
    nom: "Mot du président",
    slug: 'mot-president',
    description: 'Le président s\'adresse à tous les membres et sympathisants du club',
    image: 'content-word',
    img: '/img/help/mot-president.jpg'
  },
  {
    id: 111,
    nom: "Recrutement",
    slug: 'recrutement',
    description: 'Les opportunités pour faire partie du club',
    image: 'content-recruitment',
    img: '/img/help/recrutement.jpg'
  },
];

export const primaryColors = [
  {
    id: 201,
    name: 'red-color-primary',
    value: '#B02F38',
    className: 'color-picker-primary-1',
  },
  {
    id: 202,
    name: 'purple-color-primary',
    value: '#CB5E37',
    className: 'color-picker-primary-2',
  },
  {
    id: 203,
    name: 'darkblue-color-primary',
    value: '#D88C3B',
    className: 'color-picker-primary-3',
  },
  {
    id: 204,
    name: 'blue-color-primary',
    value: '#D7B116',
    className: 'color-picker-primary-4',
  },
  {
    id: 205,
    name: 'green-color-primary',
    value: '#80AB30',
    className: 'color-picker-primary-5',
  },
  {
    id: 206,
    name: 'yellow-color-primary',
    value: '#25834E',
    className: 'color-picker-primary-6',
  },
  {
    id: 207,
    name: 'orange-color-primary',
    value: '#2D9E93',
    className: 'color-picker-primary-7',
  },
  {
    id: 208,
    name: 'red-light-color-primary',
    value: '#3485B7',
    className: 'color-picker-primary-8',
  },
  {
    id: 209,
    name: 'purple-light-primary',
    value: '#3C5EC9',
    className: 'color-picker-primary-9',
  },
  {
    id: 210,
    name: 'darkblue-light-primary',
    value: '#813DD1',
    className: 'color-picker-primary-10',
  },
  {
    id: 211,
    name: 'blue-light-primary',
    value: '#B7367C',
    className: 'color-picker-primary-11',
  },
  {
    id: 212,
    name: 'green-light-color-primary',
    value: '#CC3555',
    className: 'color-picker-primary-12',
  },
];

export const secondaryColors = [
  {
    id: 301,
    name: 'red-color-secondary',
    value: '#B02F38',
    className: 'color-picker-secondary-1',
  },
  {
    id: 302,
    name: 'purple-color-secondary',
    value: '#CB5E37',
    className: 'color-picker-secondary-2',
  },
  {
    id: 303,
    name: 'darkblue-color-secondary',
    value: '#D88C3B',
    className: 'color-picker-secondary-3',
  },
  {
    id: 304,
    name: 'blue-color-secondary',
    value: '#D7B116',
    className: 'color-picker-secondary-4',
  },
  {
    id: 305,
    name: 'green-color-secondary',
    value: '#80AB30',
    className: 'color-picker-secondary-5',
  },
  {
    id: 306,
    name: 'yellow-color-secondary',
    value: '#25834E',
    className: 'color-picker-secondary-6',
  },
  {
    id: 307,
    name: 'orange-color-secondary',
    value: '#2D9E93',
    className: 'color-picker-secondary-7',
  },
  {
    id: 308,
    name: 'red-light-color-secondary',
    value: '#3485B7',
    className: 'color-picker-secondary-8',
  },
  {
    id: 309,
    name: 'purple-light-secondary',
    value: '#3C5EC9',
    className: 'color-picker-secondary-9',
  },
  {
    id: 310,
    name: 'darkblue-light-secondary',
    value: '#813DD1',
    className: 'color-picker-secondary-10',
  },
  {
    id: 311,
    name: 'blue-light-secondary',
    value: '#B7367C',
    className: 'color-picker-secondary-11',
  },
  {
    id: 312,
    name: 'green-light-color-secondary',
    value: '#CC3555',
    className: 'color-picker-secondary-12',
  },
  {
    id: 313,
    name: 'yellow-light-secondary',
    value: '#1A1A1A',
    className: 'color-picker-secondary-13',
  },
];
