// @flow
import React, { PureComponent } from 'react';
import type { MenuElementType } from 'types/MenuElement';
import type { ClubType } from 'types/Club';
import { handleTagEvent } from 'utils/tagManagerUtils';
import Label from 'components/fragments/text/Label';
import TopBarDropdown from './TopBarDropdown';

type StateProps = {
  topBarElements: MenuElementType[],
  tickerElements: {
    link: string,
    title: string,
    label: string
  },
  club: ClubType
};

type Props = {} & StateProps;

class TopBar extends PureComponent<Props> {
  onLinkClick = (link: string) => {
    const { club } = this.props;
    const clubName = club.nom;
    return handleTagEvent('menu_transverse', 'clic_lien_sortant', link, clubName);
  }
  
  renderTopBarElements = () => {
    const { topBarElements, club } = this.props;
    const menus = topBarElements.filter(item => item.parent === 0);
    return menus.map((menu) => {
      if (menu.items && menu.items.length > 0) {
        return <TopBarDropdown key={menu.id} subMenus={menu.items} title={menu.title} clubName={club.nom} />;
      }

      return (
        <li key={menu.id}>
          <a
            href={menu.url}
            title={`Se rendre sur le site officiel "${menu.title}" de la Fédération Française de Rugby (nouvel onglet)`}
            target="_blank"
            rel="noopener noreferrer"
            onClick={this.onLinkClick(menu.url)}
          >
            {menu.title}
          </a>
        </li>
      );
    });
  };

  render() {
    const { tickerElements } = this.props;
    return (
      <nav className="topbar">
        <ul>
          {this.renderTopBarElements()}
          {tickerElements && tickerElements.link && 
          <li className="topbar__news">
            <a href={`https://www.ffr.fr`+tickerElements.link} title={tickerElements.title}>
              <Label 
                isSmall 
                isBlueAlt 
                label={tickerElements.label ? tickerElements.label: 'La fédération'} />
              <span>{tickerElements.title}</span>
            </a>
          </li>}
        </ul>
      </nav>
    );
  }
}

export default TopBar;
