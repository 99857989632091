// @flow
import { connect } from 'react-redux';

import SupportContact from 'components/creationForm/monitoring/SupportContact';

import { postContactForm, fetchContactForm } from 'actions/contactFormActions';
import { hideModal } from 'actions/modalActions';

import type { StateType, DispatchType } from 'types/Actions';
import type { FormDataType } from 'types/ContactForm';
import type { StateProps, DispatchProps } from 'components/creationForm/monitoring/SupportContact';

export default connect(
  (state: StateType): StateProps => ({
    club: state.clubState.club,
    contactForm: state.contactFormState.contactForm,
  }),
  (dispatch: DispatchType): DispatchProps => ({
    hideModal: () => dispatch(hideModal()),
    postContactForm: (message: FormDataType, id: number) => dispatch(postContactForm(message, id)),
    fetchContactForm: () => dispatch(fetchContactForm()),
  })
)(SupportContact);
