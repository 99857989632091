// @flow
import Menu from 'components/header/Menu';
import { connect } from 'react-redux';

import { displayModal } from 'actions/modalActions';
import { forgotMagicLink } from 'actions/magicLinkActions';
import type { StateType, DispatchType } from 'types/Actions';

export default connect((state: StateType, ...ownProps: any) => ({
  menuElements: state.menuState.header,
  logo: state.settingsState.logo,
  logo_transparent: state.settingsState.logo_transparent,
  titleLigue: state.settingsState.title,
  liens_rs: state.settingsState.liens_rs,
  magicLink: state.magicLinkState.magicLink,
  email: state.magicLinkState.email,
  club: state.clubState.club
  }),
  (dispatch: DispatchType) => ({
    displayModal: (modalNb: number, content: any) => dispatch(displayModal(modalNb, content)),
    forgotMagicLink: () => dispatch(forgotMagicLink())
  }), null, { pure: false })(Menu);
