// @flow
import { connect } from 'react-redux';

import RevokeForm from 'components/creationForm/monitoring/RevokeForm';

import { postClubAction } from 'actions/clubActions';
import { hideModal } from 'actions/modalActions';

import type { StateType, DispatchType } from 'types/Actions';
import type { StateProps, DispatchProps } from 'components/creationForm/monitoring/RevokeForm';
import type { ConfigType } from 'types/Club';

export default connect(
  (state: StateType): StateProps => ({
    club: state.clubState.club,
    clubID: state.magicLinkState.clubID,
  }),
  (dispatch: DispatchType): DispatchProps => ({
    hideModal: () => dispatch(hideModal()),
    postClubAction: (clubID: number, config: ConfigType, email: string) =>
      dispatch(postClubAction(clubID, config, 'revoquer_admin', email)),
  })
)(RevokeForm);
