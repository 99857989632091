// @flow
import React, { PureComponent } from 'react';

import type { ConfigType, ClubType } from 'types/Club';

export type StateProps = {
  club: ClubType,
  clubID: number,
};

export type DispatchProps = {
  postClubAction: (clubID: number, config: ConfigType, email: string) => void,
  hideModal: () => void,
};

type Props = {
  email: string,
} & StateProps & DispatchProps;

class RevokeForm extends PureComponent<Props> {
  _handleConfirm = () => {
    const { email, postClubAction, hideModal, club: { configuration }, clubID } = this.props;

    postClubAction(clubID, { ...configuration, admin: configuration.admin.filter((admin) => admin !== email) }, email);
    hideModal();
  }

  render() {
    return (
     <a // eslint-disable-line
      className="btn btn--primary btn--full mb-2 mt-1 mt-md-2"
      onClick={this._handleConfirm}
      role="button"
      tabIndex={0}
    >
      Confirmer
     </a>
    );
  }
}

export default RevokeForm;
