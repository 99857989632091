// @flow

import { takeLatest, put, call, select } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import type { GeneratorType } from 'sagas/root';
import type { ApiExecutorType } from 'types/ApiExecutorType';
import ClubApi from 'api/club/ClubApi';
import { displayModal } from 'actions/modalActions';
import {
  FETCH_CLUB_START,
  POST_CONFIG_START,
  POST_ACTION_START,
  GET_HIT_FOR_GTM,
  getHitForGtmSuccess,
  clubFetched,
  clubFetchError,
  postClubConfigSuccess,
  postClubConfigError,
  postClubActionSuccess,
  postClubActionError,
} from 'actions/clubActions';
import { forgotMagicLink } from 'actions/magicLinkActions';
import type { 
  FETCH_CLUB_START_ACTION, 
  POST_CONFIG_START_ACTION, 
  POST_ACTION_START_ACTION, 
  GET_HIT_FOR_GTM_ACTION 
  } from 'actions/clubActions';

const content = {
  title: 'Votre code a expiré'
};

export default function(onBoardingApiExecutor: ApiExecutorType) {
  const clubApi = new ClubApi(onBoardingApiExecutor);
  const getMagicLinkInState = state => state.magicLinkState;


  return function* clubSaga(): GeneratorType {
    yield takeLatest(FETCH_CLUB_START, fetchClub);
    yield takeLatest(POST_CONFIG_START, postClubConfig);
    yield takeLatest(POST_ACTION_START, postClubAction);
    yield takeLatest(GET_HIT_FOR_GTM, getHitForGtm);
  };

  function* fetchClub(action: FETCH_CLUB_START_ACTION): Saga<void> {
    const { magicLink } = yield select(getMagicLinkInState);

    try {
      const { id } = action.payload;
      const {
        code,
        embleme,
        nom,
        configuration,
        email,
        site_status,
        url_front,
        url_back,
        emailOfficiel,
        string_blacklist,
        domaines_blacklist,
      } = yield call(clubApi.getClub, id, magicLink);
      yield put(clubFetched({
        code,
        embleme,
        nom,
        configuration,
        email,
        site_status,
        url_front,
        url_back,
        emailOfficiel,
        string_blacklist,
        domaines_blacklist
      }));
    } catch (e) {
      if (e.response.data.code === 'rest_invalid_magic_code') {
        yield put(displayModal(2, content));
      }
      yield put(forgotMagicLink());
      yield put(clubFetchError());
    }
  }

  function* postClubConfig(action: POST_CONFIG_START_ACTION): Saga<void> {
    const { magicLink } = yield select(getMagicLinkInState);

    try {
      const { id, config } = action.payload;
      const {
        code,
        embleme,
        nom,
        configuration,
        email,
        site_status,
        url_front,
        url_back,
        emailOfficiel,
        string_blacklist,
        domaines_blacklist,
      } = yield call(clubApi.postClubConfig, id, config, magicLink);
      yield put(postClubConfigSuccess({
        code,
        embleme,
        nom,
        configuration,
        email,
        site_status,
        url_front,
        url_back,
        emailOfficiel,
        string_blacklist,
        domaines_blacklist
      }));
    } catch (e) {
      if (e.response.data.code === 'rest_invalid_magic_code' && window.location.pathname === '/') {
        yield put(displayModal(2, content));
      }
      yield put(forgotMagicLink());
      yield put(postClubConfigError());
    }
  }

  function* postClubAction(action: POST_ACTION_START_ACTION): Saga<void> {
    const { magicLink } = yield select(getMagicLinkInState);

    try {
      const { id, action: actionToSend, email: emailToSend, config } = action.payload;
      const {
        code,
        embleme,
        nom,
        configuration,
        email,
        site_status,
        url_front,
        url_back,
        emailOfficiel,
        string_blacklist,
        domaines_blacklist,
      } = yield call(clubApi.postClubAction, id, config, actionToSend, emailToSend, magicLink);
      yield put(postClubActionSuccess({
        code,
        embleme,
        nom,
        configuration,
        email,
        site_status,
        url_front,
        url_back,
        emailOfficiel,
        string_blacklist,
        domaines_blacklist
      }));
    } catch (e) {
      if (e.response.data.code === 'rest_invalid_magic_code' && window.location.pathname === '/') {
        yield put(displayModal(2, content));
      }
      yield put(forgotMagicLink());
      yield put(postClubActionError(e.message));
    }
  }

  function* getHitForGtm(action: GET_HIT_FOR_GTM_ACTION): Saga<void> {
    const { magicLink } = yield select(getMagicLinkInState);

    try {
      const { id } = action.payload;
      const {
        code,
        embleme,
        nom,
        configuration,
        email,
        site_status,
        url_front,
        url_back,
        emailOfficiel,
        string_blacklist,
        domaines_blacklist,
      } = yield call(clubApi.getClub, id, magicLink);
      yield put(getHitForGtmSuccess({
        code,
        embleme,
        nom,
        configuration,
        email,
        site_status,
        url_front,
        url_back,
        emailOfficiel,
        string_blacklist,
        domaines_blacklist,
      }));
      
    } catch (e) {
     
    }
  }

}
