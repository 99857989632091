// @flow
import { combineReducers } from 'redux';
import appReducer from 'reducers/appReducer';
import menuReducer from 'reducers/menuReducer';
import settingsReducer from 'reducers/settingsReducer';
import modalReducer from 'reducers/modalReducer';
import clubReducer from 'reducers/clubReducer';
import magicLinkReducer from 'reducers/magicLinkReducer';
import contactFormReducer from 'reducers/contactFormReducer';
import newsletterSubscriptionReducer from 'reducers/newsletterSubscriptionReducer';

export default combineReducers({
  appState: appReducer,
  menuState: menuReducer,
  settingsState: settingsReducer,
  modalState: modalReducer,
  clubState: clubReducer,
  magicLinkState: magicLinkReducer,
  contactFormState: contactFormReducer,
  newsletterSubscriptionState: newsletterSubscriptionReducer,
});
