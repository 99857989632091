// @flow

import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import type { ClubType } from 'types/Club';
import { nullLocation } from 'types/Router';

export type StateProps = {
  magicLink: string,
  club: ClubType,
};

type Props = {
  component: React$ComponentType<any>,
  path: string,
  exact?: boolean,
  location?: { state: { fromCreationRoute?: boolean } | typeof undefined },
} & StateProps;

export default function PrivateRoute({
  component,
  magicLink,
  club: {
    configuration: {
      step
    }
  },
  path,
  exact,
  location
  }: Props) {
  const { state } = location || { state: undefined };

  if (magicLink !== '' && (step && step > 0) && (state === undefined || !state.fromCreationRoute)) {
    let road = '';
    if (step === 1) road = 'demarrer';
    if (step === 2) road = 'contenu';
    if (step === 21) road = 'synchronisation';
    if (step === 3) road = 'personnalisation';
    if (step === 4) road = 'verification';
    if (step === 5) road = 'recapitulatif';
    
    return <Redirect to={`/creation/etape-${step}-${road}`} />;
  }

  if (magicLink !== '' && step === 0 && (state === undefined || !state.fromCreationRoute)) {
    return <Redirect to='/creation/initialisation' />;
  }

  return <Route exact={exact} path={path} component={component} />;
}

PrivateRoute.defaultProps = {
  exact: false,
  location: nullLocation,
};
