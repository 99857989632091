// @flow
import React, { PureComponent } from 'react';

import { withHelmet } from 'infrastructure/seo/Helmet';
import CookieContainer from 'containers/footer/CookieContainer';
import Header from 'components/header/Header';
import Footer from 'components/footer/Footer';
import ModalContainer from 'containers/fragments/ModalContainer';
import type { FaviconType } from 'types/Settings';
import type { ClubType } from 'types/Club';

import {
  headerBlacklistWithSlugPaths,
  footerBlacklistWithSlugPaths,
  footerBlacklistPaths
} from 'constants/menuConstants';
import { intercomWidget, intercomInit, removeIntercom } from '../../scripts/intercom';

import Routes from "../../routes";

type HistoryProps = {
  history: any
};

export type StateProps = {
  favicons: FaviconType, // eslint-disable-line
  titleLigue: string, // eslint-disable-line
  clubID: number,
  club: ClubType,
};

export type DispatchProps = {
  fetchSettings: () => void,
  fetchMenu: () => void,
  stockMagicLink: (magicLink: string) => void,
  stockClubID: (clubID: number) => void,
  fetchClub: (id: number) => void,
};

type Props = {
  location: any
} & DispatchProps &
  StateProps &
  HistoryProps;

class App extends PureComponent<Props> {
  timeout: TimeoutID;

  interval: IntervalID;

  onInputEvent = (e: FocusEvent) => {
    if (e.type === 'focus') {
      window.inputFocused = true;
    } else if (e.type === 'blur') {
      window.inputFocused = false;
    }
  }

  onInputEvent = (e: FocusEvent) => {
    if (e.type === 'focus') {
      window.inputFocused = true;
    } else if (e.type === 'blur') {
      window.inputFocused = false;
    }
  }

  componentDidMount() {
    const {
      fetchSettings,
      fetchMenu,
      clubID,
      fetchClub,
      location : {
        search,
        state
      },
      history,
      stockMagicLink,
      stockClubID,
    } = this.props;
    const inputs = document.querySelectorAll('input');
    /**
     * Est -on en mode preview
     */
    inputs.forEach(input => {
      input.setAttribute('data-mounted', '1');
      input.addEventListener('focus', this.onInputEvent, false);
      input.addEventListener('blur', this.onInputEvent, false);
    });

    fetchSettings();
    fetchMenu();

    if (search && search.match('magic')) {
      let clubIDFromQS = search.split('&')[1];
      if(clubIDFromQS) {
        clubIDFromQS = parseInt(clubIDFromQS.split('=')[1], 10);
      }
      let magicLink = search.split('=')[1];
      if (magicLink) {
        // eslint-disable-next-line prefer-destructuring
        magicLink = magicLink.split('&')[0];
        if (clubID === 0 && clubIDFromQS) {
          stockMagicLink(magicLink);
          stockClubID(clubIDFromQS);
          fetchClub(clubIDFromQS);
        }
        stockMagicLink(magicLink);
      }
    }
    if (state !== undefined) history.replace({ pathname: '/', state: undefined });
    if (clubID) fetchClub(clubID);
  }

  componentDidUpdate(prevProps: Props) {
    const {
      club,
      location: {
        pathname
      },
      history,
      clubID,
      club: {
        configuration : {
          step
        }
      },
    } = this.props;

    const { location: { pathname: pathnamePrev }, clubID: prevClubID } = prevProps;

    if (clubID === 0 && !step && pathname !== '/') {
      history.push('/');
    }

    if (clubID !== prevClubID && step) {
      let road = '';
      if (step === 1) road = 'demarrer';
      if (step === 2) road = 'contenu';
      if (step === 21) road = 'synchronisation';
      if (step === 3) road = 'personnalisation';
      if (step === 4) road = 'verification';
      if (step === 5) road = 'recapitulatif';
      history.push(`/creation/etape-${step}-${road}`);
    }

    if (prevProps !== this.props) {
      const inputs = document.querySelectorAll('input');
      inputs.forEach(input => {
        const isMounted = input.getAttribute('data-mounted') === '1';
        if (!isMounted) {
          input.setAttribute('data-mounted', '1');
          input.addEventListener('focus', this.onInputEvent, false);
          input.addEventListener('blur', this.onInputEvent, false);
        }
      });
    }

    if (pathnamePrev !== pathname) {
      window.scrollTo(0, 0);
    }

    // à n'exécuter que sur les pages /creation
    const appId = 'ggvkhs2c';
    if (pathname.includes('/creation')) {
      const name = club.nom ? club.nom : '';
      const email = club.email ? club.email : '';
      intercomInit(appId, name, email);
      intercomWidget(appId);
    } else {
      // il va falloir supprimer les scripts intercom
      removeIntercom(appId);
    }
  }

  renderFooter() {
    const { location: { pathname } } = this.props;

    if (footerBlacklistPaths.includes(pathname)) return null;
    else if (footerBlacklistWithSlugPaths.includes(pathname.split('/')[1])) return null;
    return (
      <>
        <Footer />
      </>
    );
  }

  componentDidCatch(error, info) {
    const { history } = this.props;

    if (process.env.NODE_ENV === 'production') {
      history.push('/404');
      window.location.reload();
    }
  }

  renderHeader() {
    const { location: { pathname } } = this.props;

    if (headerBlacklistWithSlugPaths.includes(pathname.split('/')[1])) return null;
    return <Header />;
  }

  componentWillUnmount() {
    const inputs = document.querySelectorAll('input');
    inputs.forEach(input => {
      input.removeEventListener('focus', this.onInputEvent, false);
      input.removeEventListener('blur', this.onInputEvent, false);
    });
  }

  render() {
    return (
      <>
        <ModalContainer />
        {this.renderHeader()}
        <Routes />
        <CookieContainer />
        {this.renderFooter()}
      </>
    );
  }
}

// [SEO] Set default meta tags here !
function renderHelmetDefault(Helmet, pageProps) {
  return (
    <Helmet titleTemplate="%s" defaultTitle={pageProps.titleLigue ? pageProps.titleLigue : window.location.href}>
      <link rel="shortcut icon" href={pageProps.favicons['favicon-32']} />
      <link rel="apple-touch-icon" sizes="180x180" href={pageProps.favicons['favicon-180']} />
      <link rel="icon" type="image/png" sizes="32x32" href={pageProps.favicons['favicon-32']} />
      <link rel="icon" type="image/png" sizes="16x16" href={pageProps.favicons['favicon-16']} />
    </Helmet>
  );
}

export default withHelmet(App)(renderHelmetDefault);
