// @flow

import { takeLatest, put, call, select } from 'redux-saga/effects';

import {
  type ASK_MAGIC_LINK_ACTION,
  type ADD_ADMIN_DELEGATION_ACTION,
  type GET_MODAL_FOR_NEW_LINK_ACTION,
  ASK_MAGIC_LINK,
  ADD_ADMIN_DELEGATION,
  GET_MODAL_FOR_NEW_LINK,
  askMagicLinkSuccess,
  askMagicLinkFailure,
  forgotMagicLink,
} from 'actions/magicLinkActions';
import { displayModal, hideModal } from 'actions/modalActions';
import MagicLinkApi from 'api/magicLink/MagicLinkApi';
import type { Saga } from 'redux-saga';
import type { GeneratorType } from 'sagas/root';
import type { ApiExecutorType } from 'types/ApiExecutorType';

const content = {
  title: 'Vous êtes bien déconnecté.',
  text: `Votre création a bien été enregistrée. 
Revenez sur cette page pour continuer plus tard la configuration de votre site club.`
};

export default function(onBoardingApiExecutor: ApiExecutorType) {
  const magicLinkApi = new MagicLinkApi(onBoardingApiExecutor);
  const getMagicLinkInState = state => state.magicLinkState;

  return function* magicLinkSaga(): GeneratorType {
    yield takeLatest(ASK_MAGIC_LINK, askMagicLink);
    yield takeLatest(ADD_ADMIN_DELEGATION, addAdminDelegation);
    yield takeLatest (GET_MODAL_FOR_NEW_LINK, getModalForNewLink);
  };

  function* addAdminDelegation(action: ADD_ADMIN_DELEGATION_ACTION) {
    const { magicLink, clubID } = yield select(getMagicLinkInState);
    try {
      const { email } = action.payload;
      yield call(magicLinkApi.addAdminDelegation, email, magicLink, clubID);
    } catch (e) {
      // @TODO: manage possible errors, surely with a pop-up
      console.log(e); // eslint-disable-line
    }
  }

  function* askMagicLink(action: ASK_MAGIC_LINK_ACTION): Saga<void> {
    try {
      const { email } = action.payload;
      const { club_id } = yield call(magicLinkApi.askMagicLink, email);
      yield put(askMagicLinkSuccess(club_id, email));
      yield put(hideModal());
      yield put(displayModal(1, {
        title: 'Demande enregistrée',
        text: 'Votre demande est prise en compte veuillez consulter vos mails'
      }));
    } catch (e) {
      if (window.snack) {
        const { snack } = window;
        snack.classList.add('is-valid');
        setTimeout(() => {
          snack.classList.remove('is-valid');
        }, 3000);
      }
      yield put(askMagicLinkFailure());
    }
  }

  function* getModalForNewLink(action: GET_MODAL_FOR_NEW_LINK_ACTION) : Saga<void> {
    try{
      yield put(forgotMagicLink());
      yield put(hideModal());
      yield put(displayModal(12, content, true));
    }
    catch (e){
      console.log(e); // eslint-disable-line
    }
  }

}
