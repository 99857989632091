// @flow

/**
 * Only for debug env
 * Use idf as default api source
 * instead of host name
 */
function getBaseUrl(): string {
  let source = 'pp.website.ffr.nextmap.io';
  if (window.location.hostname === 'pp.onboarding.clubs.ffr.nextmap.io') {
    source = window.location.hostname ;
  }
  // if (process.env.NODE_ENV === 'production') {
  //   source = window.location.hostname;
  // }

  return `https://api.${source}`;
}

function getOnboardingBaseUrl(): string {
  let source = 'pp.onboarding.clubs.ffr.nextmap.io';
  if (process.env.NODE_ENV === 'production') {
    source = window.location.hostname;
  }

  return `https://api.${source}`;
}

export const TIMEOUT = 15000;
export const BASE_URL = getBaseUrl() + '/wp-json';
export const ON_BOARDING_BASE_URL = getOnboardingBaseUrl() + '/wp-json';
export const STATIC_BASE_URL = getBaseUrl() + '/static';
export const FORM_CONTACT_TOKEN = 'YXBpLWZmcjowUmZ4TkdCJXl3V0huVTNsWWdENzdjNTI=';
