// @flow
import React from 'react';
import { handleTagEvent } from 'utils/tagManagerUtils';
import type { liensRsType } from 'types/Settings';
import NewsletterSubscriberContainer from 'containers/footer/NewsletterSubscriberContainer';

export type StateProps = {
  titleLigue: string,
  liens_rs: liensRsType
};

const FooterTop = (props: StateProps) => {
  const {
    liens_rs: {
      fb_lien,
      twitter_lien,
      instagram_lien,
      youtube_lien
    },
    titleLigue,
  } = props;

  if (fb_lien === '' && twitter_lien === '' && instagram_lien === '' && youtube_lien === '') {
    return null;
  }

  return (
    <div className="container footer__top">
      <div className="row">
        <div className="col col-lg-3">
          <h5 className="mb-1">Nous suivre</h5>
          <div className="mt-3 mb-5">
            {
              fb_lien &&
              <a
                className="link-icon is-dark"
                href={fb_lien}
                target="_blank"
                rel="noopener noreferrer"
                title={`Se rendre sur la page Facebook de ${titleLigue}`}
                onClick={handleTagEvent('footer', 'clic_icone_sociale', 'facebook')}>
                <i className="icon icon-facebook" />
              </a>
            }
            {
              twitter_lien &&
              <a
                className="link-icon is-dark"
                href={twitter_lien}
                target="_blank"
                rel="noopener noreferrer"
                title={`Se rendre sur la page Twitter de ${titleLigue}`}
                onClick={handleTagEvent('footer', 'clic_icone_sociale', 'twitter')}>
                <i className="icon icon-twitter" />
              </a>
            }
            {
              instagram_lien &&
              <a
                className="link-icon is-dark"
                href={instagram_lien}
                target="_blank"
                rel="noopener noreferrer"
                title={`Se rendre sur la page Instagram de ${titleLigue}`}
                onClick={handleTagEvent('footer', 'clic_icone_sociale', 'instagram')}>
                <i className="icon icon-instagram" />
              </a>
            }
            {
              youtube_lien &&
              <a
                className="link-icon is-dark"
                href={youtube_lien}
                target="_blank"
                rel="noopener noreferrer"
                title={`Se rendre sur la page Youtube de ${titleLigue}`}
                onClick={handleTagEvent('footer', 'clic_icone_sociale', 'youtube')}>
                <i className="icon icon-youtube" />
              </a>
            }
          </div>
        </div>
        <NewsletterSubscriberContainer />
      </div>
      <hr />
    </div>
  );
};

export default FooterTop;
