// @flow
import TagManager from 'react-gtm-module';

export function handleTagEvent(
  eventCategory: string,
  eventAction: ?string,
  eventLabel: ?string |?number,
  club: ?string,
  onClickActions?: Function,
) {
  return () => {
    TagManager.dataLayer({
      dataLayer: {
        'event': 'eventGA',
        'eventCategory' : eventCategory,
        'eventAction' : eventAction,
        'eventLabel' : eventLabel,
        'club' : club
      },
      dataLayerName: 'dataLayer'
    });
    if(onClickActions){
      onClickActions();
    }
  };
};

export const getSocialName = (props: Object) => {
  const { isFacebook, isInstagram, isMail, isTwitter } = props;
  if (isFacebook) {
    return 'facebook';
  } else if (isTwitter) {
    return 'twitter';
  } else if (isInstagram) {
    return 'instagram';
  } else if (isMail) {
    return 'mail';
  };
  return '';
};

export const getModalName = (modalNb: number) => {
  switch (modalNb) {
    case 0:
      return '';
    case 1:
      return 'Modale inscription Newsletter réussie';
    case 2:
      return 'Modale échec inscription Newsletter';
    case 21:
      return 'Modale échec inscription newsletter';
    case 3:
      return 'Modale newsletter optin manquant';
    case 4:
      return 'Modale formulaire contact succès';
    case 5:
      return 'Modale formulaire contact échec';
    case 6:
      return 'Modale formulaire contact loge';
    case 7:
      return 'Modale inscription loge succès';
    case 8:
      return 'Modale inscription Newsletter';
    case 9:
      return 'Modale inscription séminaire';
    default:
      return null;
  }
};

export const sendTags = (category: string, action: string, label?: string) => {
  TagManager.dataLayer({
    dataLayer: {
      'event': 'eventGA',
      'eventCategory' : category,
      'eventAction' : action,
      'eventLabel' : label,
    },
    dataLayerName: 'dataLayer',
  });
}; 

//
// export function getFilterReduced(practices: Array<string>, competitions: Array<string>, distance: string){
//   let reducedPractices = '';
//   let reducedCompetitions = '';
//   if (practices.length > 0) {
//     reducedPractices = practices.reduce((acc, practice) => `${acc}|${practice}`);
//   }
//
//   if (competitions.length > 0) {
//     reducedCompetitions = competitions.reduce((acc, competitions) => `${acc}|${competitions}`);
//   }
//
//   if (reducedPractices !== '' && reducedCompetitions !== '') {
//     if (distance) {
//       return `${reducedPractices}|${reducedCompetitions && reducedCompetitions}|${distance}km`;
//     }
//     return `${reducedPractices}|${reducedCompetitions && reducedCompetitions}`;
//   } else if (reducedPractices !== '') {
//     if (distance) {
//       return `${reducedPractices}|${distance}km`;
//     }
//     return `${reducedPractices}`;
//   } else if (reducedCompetitions !== '') {
//     if (distance) {
//       return `${reducedCompetitions}|${distance}km`;
//     }
//     return `${reducedCompetitions}`;
//   }
// }
