// @flow
import React, { PureComponent } from 'react';

type OwnProps = {};

export type StateProps = {
  showCookieInfo: boolean,
  title: string,
};

export type DispatchProps = {
  closeCookieInfo: () => void
};

type Props = OwnProps & StateProps & DispatchProps;

const cookieEndContent =
  ", vous acceptez les CGU ainsi que l'utilisation de cookies et de technologies similaires, y compris celle" +
  ' de partenaires tiers, pour vous proposer des contenus pertinents et des publicités ciblées en fonction' +
  " de vos centres 'intérêts.";

class Cookie extends PureComponent<Props> {
  render() {
    const {
      title,
      showCookieInfo,
      closeCookieInfo
    } = this.props;

    if (showCookieInfo)
      return (
        <div className="box cookie">
          <p>
            En poursuivant votre navigation sur
            {' '}
            {title}
            {cookieEndContent}
            <br />
            <a href="https://www.ffr.fr/politique-de-cookies" target="_blank" rel="noopener noreferrer">
                En savoir plus sur les cookies
            </a>
            {' '}
            <a href="https://www.ffr.fr/cgu" target="_blank" rel="noopener noreferrer">
              Afficher les CGUs
            </a>
          </p>
          <div
            onClick={closeCookieInfo}
            className="btn btn--primary btn--full"
            role='button'
            tabIndex={0}>
            Accepter les cookies
          </div>
        </div>
      );

    return null;
  }
}

export default Cookie;
