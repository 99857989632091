// @flow
import type { ApiExecutorType } from 'types/ApiExecutorType';
import type { ClubType, ConfigType } from 'types/Club';
import { getOnboardingMagicHeader } from 'utils/requestUtils';

function ClubApi(executor: ApiExecutorType) {
  return { getClub, postClubConfig, postClubAction };

  function getClub(id: number, magicLink: string): Promise<ClubType> {
    return executor.get(`ffr/v1/onboarding_club?clubid=${id}`, getOnboardingMagicHeader(magicLink));
  }

  function postClubConfig(clubid: number, configuration: ConfigType, magicLink: string): Promise<ClubType> {
    return executor.post('ffr/v1/onboarding_club', { clubid, configuration }, getOnboardingMagicHeader(magicLink));
  }

  function postClubAction(
    clubid: number,
    configuration: ConfigType,
    action: string,
    email: string,
    magicLink: string
  ): Promise<ClubType> {
    return executor.post('ffr/v1/onboarding_club', {
      clubid,
      configuration,
      action,
      email
    }, getOnboardingMagicHeader(magicLink));
  }
}

export default ClubApi;
