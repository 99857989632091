// @flow
import React, { PureComponent } from 'react';
import { dynamicClassName } from 'utils/dynamicClassName';
import { withRouter } from 'react-router-dom';
import { sendTags } from 'utils/tagManagerUtils';

type Props = {
  onSetOverlay?: (overlay: boolean) => void,
  mobile?: boolean,
  history: any,
  onCloseMenu?: Function
};

type ComponentState = {
  focused: boolean,
  value: string
};

class Search extends PureComponent<Props, ComponentState> {
  _node: any;

  static defaultProps = {
    mobile: false,
    onSetOverlay: () => {},
    onCloseMenu: () => {},
  };

  state: ComponentState = {
    focused: false,
    value: '',
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick);
  }

  setFocused(focused: boolean) {
    const { onSetOverlay } = this.props;
    this.setState({ focused });
    if (onSetOverlay) onSetOverlay(focused);
  }

  onFocus = () => {
    this.setFocused(true);
  };

  handleClick = (e: MouseEvent) => {
    if (!this._node.contains(e.target)) this.setFocused(false);
  };

  handleSubmit = (e: Event) => {
    const { value } = this.state;
    const { history, onCloseMenu } = this.props;

    e.preventDefault();
    sendTags('recherche_classique','recherche', `/recherche?s=${value}`);

    history.replace(`/recherche?s=${value}`);

    this.setFocused(false);
    this.setState({ value: '' });

    if (onCloseMenu) {
      onCloseMenu();
    }
  }

  handleChange = (e: Event) => {
    if (e.target instanceof HTMLInputElement) {
      const { target } = e;
      this.setState({ value: target.value });
    }
  }

  _onKeyUp = (event: any) => {
    if (event.keyCode === 13) {
      event.target.blur();
    }
  }


  render() {
    const { mobile } = this.props;
    const { focused, value } = this.state;
    let formLabel;

    const classNamesInput = dynamicClassName('input input--search input--expand');
    focused && classNamesInput.add('is-open has-overlay');

    if (mobile) {
      formLabel = "form_recherche_mobile";
    } else {
      formLabel = "form_recherche";
    }

    return (
      <form ref={node => (this._node = node)} onSubmit={this.handleSubmit}>
        <div className={classNamesInput.build()} data-overlay="false">
          <input
            type="search"
            id={formLabel}
            title="Effectuer une recherche sur le site"
            tabIndex={0}
            placeholder="Rechercher"
            onFocus={this.onFocus}
            onKeyUp={this._onKeyUp}
            value={value}
            onChange={this.handleChange}
            aria-label="Effectuer une recherche sur le site"
             />
        </div>
      </form>
    );
  }
}

export default withRouter(Search);
