/* eslint-disable prefer-rest-params */
export const intercomInit = (appId, name, email) => {
  window.intercomSettings = {
    app_id: appId,
    name: name, // Full name
    email: email, // Email address
    site_is_published: false, // sur le onboarding il est obligatoirement non publié
    created_at: "1312182000" // Signup date as a Unix timestamp
  };
};

export const intercomWidget = (appId) => {
  return (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/' + appId ;var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
};

export const removeIntercom = (appId) => {
  window.intercomSettings = {};

  const iframe = document.getElementById("intercom-frame");
  if (iframe) iframe.parentNode.removeChild(iframe);

  const app = document.querySelectorAll(".intercom-lightweight-app");
  if (app) {
    app.forEach(element => {
      element.parentNode.removeChild(element);
    });
  }

  const script = document.querySelectorAll(`script[src="https://widget.intercom.io/widget/${appId}"]`);
  if (script) {
    script.forEach(element => {
      element.parentNode.removeChild(element);
    });
  }
};